import typography from "./typography"
import color from "./color"
import media from "./media"

export default {
  typography,
  color,
  media,
  spacing: function(n) {
    return `${n}rem`
  },
}
