import React from "react";
import styled from "@emotion/styled";


const Footer = styled.footer`

  background-color: ${props => props.theme.color.secondary.dark};
  color: white;

  padding: ${props => props.theme.spacing(1)};
  a {
    color: white;
    font-weight: bold;
  }
`;

export default () => (<Footer>
  © {new Date().getFullYear()} A product of
    {` `}
  <a href="https://www.fonestar.es">Fonestar</a>
</Footer>)