import React from "react"
import { ThemeProvider } from "@emotion/react"
import theme from "../../theme"
import Helmet from "react-helmet"

export default ({ children }) => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Cousine|Open+Sans"
        rel="stylesheet"
      />
    </Helmet>

    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </>
)
