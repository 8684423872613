const alpha = value => `rgba(0, 0, 0, ${value})`

export default {
  primary: "#cf152d",
  primaryDark: "#c30f22",
  primaryLight: "#e78a96",
  secondary: { light: "#bacada", main: "#8a99a8", dark: "#5d6b79" },

  text: {
    high: alpha(0.87),
    medium: alpha(0.6),
    disabled: alpha(0.38),
    light: alpha(0.12),
  },
}
