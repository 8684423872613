const breakpoints = { sm: "576px", md: "768px", lg: "992px", xl: "1200px" }

const devices = {
  mobile: `@media (max-width: ${breakpoints.sm})`,
  tablet: `@media (min-width: ${breakpoints.sm})`,
  desktop: `@media (min-width: ${breakpoints.md})`,
  largeDesktop: `@media (min-width: ${breakpoints.lg})`,
  superLargeDesktop: `@media (min-width: ${breakpoints.xl})`,
}

function lessThan(max) {
  const value = breakpoints[max]
  return `@media (max-width: ${value})`
}

function between(min, max) {
  min = breakpoints[min]
  max = breakpoints[max]
  return `@media (min-width: ${min}) and (max-width: ${max})`
}

function moreThan(min) {
  min = breakpoints[min]
  return `@media (min-width: ${min})`
}

export default {
  lessThan,
  between,
  moreThan,
  breakpoints,
  devices,
}
